// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dashboard-page-js": () => import("./../../../src/pages/dashboard-page.js" /* webpackChunkName: "component---src-pages-dashboard-page-js" */),
  "component---src-templates-archief-page-js": () => import("./../../../src/templates/archief-page.js" /* webpackChunkName: "component---src-templates-archief-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-vragen-page-js": () => import("./../../../src/templates/vragen-page.js" /* webpackChunkName: "component---src-templates-vragen-page-js" */)
}

